import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { apiCallBegan } from "../api";
import moment from "moment";
import * as _ from "lodash";
import { Immer } from "immer";
import { Dispatch } from 'redux';
import { useAppSelector } from "../hooks";
interface initState {
  msgtype: string[],
  list: any[],
  FClist: any[],
  loading: boolean,
  lastFetch: Date | null,
  keywords: any[],
  contacts: any[],
  contactmsgs: any[],
  survaycallcenter: {
    callcentercount: number,
    notcallcentercount: number,
    finishsurveycount: number,
    freewritecount: number
  },
  survaycallcenterDetails: {
    callcentercount: any[],
    notcallcentercount: any[],
    finishsurveycount: any[],
    freewritecount: []
  },
  survaycallcenterDetailsWMobile: {
    callcentercount: any[],
    notcallcentercount: any[],
    finishsurveycount: any[],
    freewritecount: []
  },
  survayL2: {

    VSTime: number,
    VSHelpfulness: number,
    VSCapability: number,
    STime: number,
    SHelpfulness: number,
    SCapability: number,
    NSTime: number,
    NSHelpfulness: number,
    NSCapability: number

  },
  survayL2Details: {

    VSTime: any[],
    VSHelpfulness: any[],
    VSCapability: any[],
    STime: any[],
    SHelpfulness: any[],
    SCapability: any[],
    NSTime: any[],
    NSHelpfulness: any[],
    NSCapability: any[]

  },
  survayTotalTempMsg: {

    totaltempmsg: number,
    nothaswpcount: number,
    haswpcount: number

  },
  survayTotalTempMsgDetails: {

    totaltempmsg: any[],
    nothaswpcount: any[],
    haswpcount: any[]

  },
  survayl1: {
    VSD: any[],
    SD: any[],
    NSD: any[],
    VS: number,
    S: number,
    NS: number

  },
  filter: {
    IMName: string,
    IMKeyword: string,
    IMRowConnected: boolean,
    msgid: number,
    actionmsgid: number,
    im_id_reply: string,
    keywordid: number,
    isinteractive: boolean,
    msgType: string,
    filtername: string
  },
};
const initialState: initState = {
  msgtype: ['interactive', 'text', 'freetext', 'image', 'document', 'location', 'video', 'audio', 'contact', 'product', 'product_list', 'template'],
  // text: [],
  // video:[],
  // audio:[],
  // voice:[],
  // location:[],
  // sticker:[],
  // contact:[],
  // document:[],
  // im_button:[],
  // im_list:[],
  // temp_message:[],
  list: [],
  FClist: [],
  keywords: [],
  loading: false,
  lastFetch: null,
  contacts: [],
  contactmsgs: [],
  survaycallcenter: {
    callcentercount: 0,
    notcallcentercount: 0,
    finishsurveycount: 0,
    freewritecount: 0
  },
  survaycallcenterDetails: {
    callcentercount: [],
    notcallcentercount: [],
    finishsurveycount: [],
    freewritecount: []
  },
  survaycallcenterDetailsWMobile: {
    callcentercount: [],
    notcallcentercount: [],
    finishsurveycount: [],
    freewritecount:[]
  },
  survayL2: {

    VSTime: 0,
    VSHelpfulness: 0,
    VSCapability: 0,
    STime: 0,
    SHelpfulness: 0,
    SCapability: 0,
    NSTime: 0,
    NSHelpfulness: 0,
    NSCapability: 0

  },
  survayL2Details: {

    VSTime: [],
    VSHelpfulness: [],
    VSCapability: [],
    STime: [],
    SHelpfulness: [],
    SCapability: [],
    NSTime: [],
    NSHelpfulness: [],
    NSCapability: []

  },
  survayTotalTempMsgDetails: {

    totaltempmsg: [],
    nothaswpcount: [],
    haswpcount: []

  },
  survayTotalTempMsg: {

    totaltempmsg: 0,
    nothaswpcount: 0,
    haswpcount: 0

  },
  survayl1: {
    VSD: [],
    SD: [],
    NSD: [],
    VS: 0,
    S: 0,
    NS: 0

  },
  filter: {
    IMName: "",
    IMKeyword: "",
    IMRowConnected: false,
    msgid: 0,
    im_id_reply: "",
    actionmsgid: 0,
    keywordid: 0,
    isinteractive: false,
    msgType: "",
    filtername: ""
  },
};

const slice = createSlice({
  name: "listmessages",
  initialState,
  reducers: {
    RequestSurvayCC: (listmessages, action) => {
      listmessages.survaycallcenter = action.payload;
      listmessages.loading = false;
    },
    RequestSurvayL2Details: (listmessages, action) => {
      listmessages.survayL2Details = action.payload;
      listmessages.loading = false;
    },
    RequestSurvayL2: (listmessages, action) => {
      listmessages.survayL2 = action.payload;
      listmessages.loading = false;
    },
    RequestSurvayTempMsg: (listmessages, action) => {
      listmessages.survayTotalTempMsg = action.payload;
      listmessages.loading = false;
    },

    RequestSurvayL1: (listmessages, action) => {
      listmessages.survayl1 = action.payload;
      listmessages.loading = false;
    },
    RequestSurvayCCDetailsWMObile: (listmessages, action) => {
      listmessages.survaycallcenterDetailsWMobile = action.payload;
      listmessages.loading = false;
    },

    RequestSurvayCCDetails: (listmessages, action) => {
      listmessages.survaycallcenterDetails = action.payload;
      listmessages.loading = false;
    },
    RequestSurvayCTRDetails: (listmessages, action) => {
      listmessages.survayL2Details = action.payload;
      listmessages.loading = false;
    },
    RequestSurvayTempMsgDetails: (listmessages, action) => {
      listmessages.survayTotalTempMsgDetails = action.payload;
      listmessages.loading = false;
    },


    RequestList: (listmessages, action) => {
      listmessages.list = action.payload.results;
      listmessages.loading = false;
    },

    RequestContactsList: (listmessages, action) => {
      listmessages.contacts = action.payload.results;
      listmessages.loading = false;
    },
    RequestContactMessagesList: (listmessages, action) => {
      listmessages.contactmsgs = action.payload.results;
      listmessages.loading = false;
    },

    RequestFCList: (listmessages, action) => {
      listmessages.FClist = action.payload.results;
      listmessages.loading = false;
    },
    RequestKeywords: (listmessages, action) => {
      listmessages.keywords = action.payload.results;
      listmessages.loading = false;
    },
    RequesAddMsg: (listmessages, action) => {
      const list = current(listmessages.list);
      listmessages.list = [...list, action.payload];
      listmessages.loading = false;
    },
    //RequesAddFC
    RequesAddFC: (listmessages, action) => {
      const listfc = current(listmessages.FClist);
      listmessages.FClist = [...listfc, action.payload];
      listmessages.loading = false;
    },
    RequesAddKeyword: (listmessages, action) => {
      const keywords_ = current(listmessages.keywords);
      let list = current(listmessages.list);
      if (keywords_) { listmessages.keywords = [...keywords_, action.payload]; }
      else { listmessages.keywords = action.payload; }
      console.log(action.payload);
      listmessages.filter.keywordid = action.payload.id;
      listmessages.filter.actionmsgid = action.payload.actionmsgid;
      listmessages.loading = false;

    },
    RequesEditMsg: (listmessages, action) => {
      const list = current(listmessages.list);
      const indexlist = list.findIndex(
        (a) => a.id === action.payload.id
      );
      listmessages.list[indexlist] = action.payload
      listmessages.loading = false;
    },

    RequesDeleteMsg: (listmessages, action) => {
      const list = current(listmessages.list);
      listmessages.list = list.filter((a) => a.id !== action.payload.id)
      listmessages.loading = false;
    },
    //RequesDeleteFC
    RequesDeleteFC: (listmessages, action) => {
      const listfc = current(listmessages.FClist);
      listmessages.FClist = listfc.filter((a) => a.id !== action.payload.id)
      listmessages.loading = false;
    },
    RequesEditFC: (listmessages, action) => {
      const listfc = current(listmessages.FClist);
      const indexlist = listfc.findIndex(
        (a) => a.id === action.payload.id
      );
      listmessages.FClist[indexlist] = action.payload
      listmessages.loading = false;
    },
    RequesDeleteKeyword: (listmessages, action) => {
      const keywords = current(listmessages.keywords);

      listmessages.keywords = keywords.filter((a) => a.id !== action.payload.id)


      listmessages.filter.actionmsgid = 0;
      listmessages.filter.keywordid = 0;

      listmessages.loading = false;
      //  listmessages.list = list.map((a) => a.id === action.payload.messages_company.id ? action.payload.messages_company:a);
    },

    RequestStart: (listmessages, action) => {
      listmessages.loading = true;
    },
    RequestFinish: (listmessages, action) => {
      listmessages.loading = false;
    },
    RequestFailed: (listmessages, action) => {
      listmessages.loading = false;
    },
    Requestsearch: (listmessages, action) => {
      listmessages.filter.msgType = action.payload.msgType;
      listmessages.filter.filtername = action.payload.filtername;
      listmessages.loading = false;
    },

    RequestChangeKeywordFilter: (listmessages, action) => {
      listmessages.filter.msgid = action.payload.msgid;
      listmessages.filter.im_id_reply = action.payload.im_id_reply;
      listmessages.filter.actionmsgid = action.payload.actionmsgid;
      listmessages.filter.keywordid = action.payload.keywordid;

      listmessages.filter.isinteractive = action.payload.isinteractive
      listmessages.loading = false;
    },
  },
});

export const {
  RequestList,
  RequestFCList,
  RequestKeywords,
  RequestStart,
  RequestFinish,
  RequestFailed,
  RequesAddMsg,
  RequesAddFC,
  RequesAddKeyword,
  RequesEditMsg,
  RequesDeleteMsg,
  RequestContactsList,
  RequestContactMessagesList,
  RequesEditFC,
  RequesDeleteFC,
  RequesDeleteKeyword,
  RequestChangeKeywordFilter,
  Requestsearch,
  RequestSurvayTempMsgDetails,
  RequestSurvayCTRDetails,
  RequestSurvayCCDetails,
  RequestSurvayCCDetailsWMObile,
  RequestSurvayL1,
  RequestSurvayTempMsg,
  RequestSurvayL2Details,
  RequestSurvayL2,
  RequestSurvayCC,
} = slice.actions;
export default slice.reducer;

// Action Creators


const urlML = "api2/MessageCompany/";
const urlKL = "api2/Keyword/";
const deleteKw = "api2/deletekw/";
//api2/FlowChart/
const urlFC = "api2/FlowChart/";
const urlContact = "api2/contact/";


const urlContactMessages = "api2/MessageList/?ordering=-id&contacts__id=";
export const ChangekeywordFilter = (data: any) => (dispatch: Dispatch) => {
  return dispatch(RequestChangeKeywordFilter(data));
};

export const ChangeSearchFilter = (data: any) => (dispatch: Dispatch) => {
  return dispatch(Requestsearch(data));
};
export const LoadSurvayDashboard = () => {
  return (dispatch: Dispatch) => {
    dispatch(
      apiCallBegan({
        url: "api2/getCountL2/",
        onStart: RequestStart.type,
        onSuccess: RequestSurvayL2.type,
        onError: RequestFailed.type,
      })
    );
    dispatch(
      apiCallBegan({
        url: "api2/getCountL2Details/",
        onStart: RequestStart.type,
        onSuccess: RequestSurvayL2Details.type,
        onError: RequestFailed.type,
      })
    );
    dispatch(
      apiCallBegan({
        url: "api2/getCountTempMsg/",
        onStart: RequestStart.type,
        onSuccess: RequestSurvayTempMsg.type,
        onError: RequestFailed.type,
      })
    );


    dispatch(
      apiCallBegan({
        url: "api2/getCountTempMsgDeatils/",
        onStart: RequestStart.type,
        onSuccess: RequestSurvayTempMsgDetails.type,
        onError: RequestFailed.type,
      })
    );
    dispatch(
      apiCallBegan({
        url: "api2/getCountL1Details/",
        onStart: RequestStart.type,
        onSuccess: RequestSurvayL1.type,
        onError: RequestFailed.type,
      })
    );

    dispatch(
      apiCallBegan({
        url: "api2/getcallsentercount/",
        onStart: RequestStart.type,
        onSuccess: RequestSurvayCC.type,
        onError: RequestFailed.type,
      })
    );
    dispatch(
      apiCallBegan({
        url: "api2/getcallsentercountDetails/",
        onStart: RequestStart.type,
        onSuccess: RequestSurvayCCDetails.type,
        onError: RequestFailed.type,
      })
    );
    dispatch(
      apiCallBegan({
        url: "api2/getcallsentercountDetailsWMobile/",
        onStart: RequestStart.type,
        onSuccess: RequestSurvayCCDetailsWMObile.type,
        onError: RequestFailed.type,
      })
    );


  };
}







export const LoadMessagesList = () => (dispatch: Dispatch) => {
  return dispatch(
    apiCallBegan({
      url: urlML,
      onStart: RequestStart.type,
      onSuccess: RequestList.type,
      onError: RequestFailed.type,
    })
  );
};

export const LoadContactsList = () => (dispatch: Dispatch) => {
  return dispatch(
    apiCallBegan({
      url: urlContact,
      onStart: RequestStart.type,
      onSuccess: RequestContactsList.type,
      onError: RequestFailed.type,
    })
  );
};


export const LoadContactMessagesList = (contactid: number) => (dispatch: Dispatch) => {
  return dispatch(
    apiCallBegan({
      url: urlContactMessages + contactid,
      onStart: RequestStart.type,
      onSuccess: RequestContactMessagesList.type,
      onError: RequestFailed.type,
    })
  );
};


export const LoadFCList = () => (dispatch: Dispatch) => {
  return dispatch(
    apiCallBegan({
      url: urlFC,
      onStart: RequestStart.type,
      onSuccess: RequestFCList.type,
      onError: RequestFailed.type,
    })
  );
};




export const LoadkeywordsList = () => (dispatch: Dispatch) => {
  return dispatch(
    apiCallBegan({
      url: urlKL,
      onStart: RequestStart.type,
      onSuccess: RequestKeywords.type,
      onError: RequestFailed.type,
    })
  );
};


export const EditMessagesToList = (msg: any) =>
  (dispatch: Dispatch) => {

    return dispatch(
      apiCallBegan({
        url: urlML + msg.get("id") + "/",
        method: "put",
        data: msg,
        onStart: RequestStart.type,
        onSuccess: RequesEditMsg.type,

        onError: RequestFailed.type,
      })
    );

  }



export const DeleteKeywordToList = (val: any) => {
  return (dispatch: Dispatch) => {
    dispatch(
      apiCallBegan({
        url: urlKL + val + "/",
        method: "delete",
        onStart: RequestStart.type,
        onSuccess: RequesDeleteKeyword.type,
        onError: RequestFailed.type,
      })
    );

  }
}

export const deletekw = (val1: number, val2: number, val3: string) => {
  return (dispatch: Dispatch) => {
    dispatch(
      apiCallBegan({
        url: deleteKw + val1 + "/" + val2 + "/" + val3,
        method: "delete",
        onStart: RequestStart.type,
        onSuccess: RequesDeleteKeyword.type,
        onError: RequestFailed.type,
      })
    );

  }
}


export const DeleteMessagesToList = (val: any) => {
  return (dispatch: Dispatch) => {
    dispatch(
      apiCallBegan({
        url: urlML + val.id + "/",
        method: "delete",
        onStart: RequestStart.type,
        onSuccess: RequesDeleteMsg.type,
        onError: RequestFailed.type,
      })
    );

  }
}


//RequesEditFC



export const EditFCToList = (msg: any) =>
  (dispatch: Dispatch) => {

    return dispatch(
      apiCallBegan({
        url: urlFC + msg.get("id") + "/",
        method: "put",
        data: msg,
        onStart: RequestStart.type,
        onSuccess: RequesEditFC.type,

        onError: RequestFailed.type,
      })
    );

  }
export const DeleteFCToList = (val: any) => {
  return (dispatch: Dispatch) => {
    dispatch(
      apiCallBegan({
        url: urlFC + val.id + "/",
        method: "delete",
        onStart: RequestStart.type,
        onSuccess: RequesDeleteFC.type,
        onError: RequestFailed.type,
      })
    );

  }
}


export const AddFC = (msg: any) => (dispatch: Dispatch) => {

  return dispatch(
    apiCallBegan({
      url: urlFC,
      method: "post",
      data: msg,
      onStart: RequestStart.type,
      onSuccess: RequesAddFC.type,
      onError: RequestFailed.type,
    })
  );
};


export const AddKeywordMsg = (msg: any) => (dispatch: Dispatch) => {

  return dispatch(
    apiCallBegan({
      url: urlKL,
      method: "post",
      data: msg,
      onStart: RequestStart.type,
      onSuccess: RequesAddKeyword.type,
      onError: RequestFailed.type,
    })
  );
};


export const AddMessagesToList = (msg: any) => (dispatch: Dispatch) => {

  return dispatch(
    apiCallBegan({
      url: urlML,
      method: "post",
      data: msg,

      onStart: RequestStart.type,
      onSuccess: RequesAddMsg.type,
      onError: RequestFailed.type,
    })
  );
};

//getImageListMessages
export const getVideoListMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list) => _.orderBy(
    list.filter((msg: any) => msg.msgtype === "video"),
    ["id"],
    ["desc"])


);
export const getAudioListMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list) => _.orderBy(
    list.filter((msg: any) => msg.msgtype === "audio"),
    ["id"],
    ["desc"])


);
export const getDocumentListMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list) => _.orderBy(
    list.filter((msg: any) => msg.msgtype === "document"),
    ["id"],
    ["desc"])


);
export const getStickerListMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list) => _.orderBy(
    list.filter((msg: any) => msg.msgtype === "sticker"),
    ["id"],
    ["desc"])


);
export const getContactListMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list) => _.orderBy(
    list.filter((msg: any) => msg.msgtype === "contacts"),
    ["id"],
    ["desc"])


);
export const getLocationListMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list) => _.orderBy(
    list.filter((msg: any) => msg.msgtype === "location"),
    ["id"],
    ["desc"])


);
export const getImageListMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list) => _.orderBy(
    list.filter((msg: any) => msg.msgtype === "image"),
    ["id"],
    ["desc"])


);


export const getInteractiveListMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list) => _.orderBy(
    list.filter((msg: any) => msg.msgtype === "interactive" && msg.text_body === "list"),
    ["id"],
    ["desc"])


);
export const getInteractiveButtonsMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list) => _.orderBy(
    list.filter((msg: any) => msg.msgtype === "interactive" && msg.text_body === "buttons"),
    ["id"],
    ["desc"])


);
export const getTextListMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list) => _.orderBy(
    list.filter((msg: any) => msg.msgtype === "text"),
    ["id"],
    ["desc"])


);

export const getFreeTextListMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list) => _.orderBy(
    list.filter((msg: any) => msg.msgtype === "freetext"),
    ["id"],
    ["desc"])


);
export const getListMessages = createSelector(
  (state: any) => state.entities.listmessages.list,

  (list: any) => list

);

export const getFCList = createSelector(
  (state: any) => state.entities.listmessages.FClist,

  (FClist: any) => FClist

);

export const getLoading = createSelector(
  (state: any) => state.entities.listmessages.loading,

  (loading: boolean) => loading

);

export const filterMsgByID =
  createSelector(
    (state: any) => state.entities.listmessages.list,
    (state: any) => state.entities.listmessages.filter,
    (state: any) => state.entities.listmessages.keywords,
    (list: any, filter: any, keywords: any) =>
      filter.isinteractive === true && filter.actionmsgid === 0 && filter.im_id_reply === "" ?
        [] : filter.isinteractive === true && filter.actionmsgid === 0 && filter.im_id_reply !== ""
          ? list : filter.isinteractive === true && filter.actionmsgid !== 0 && filter.im_id_reply !== ""
            ? list.filter((a: any) => a.id === filter.actionmsgid)
            : filter.isinteractive === false && filter.actionmsgid === 0 ? list :
              list.filter((a: any) => a.id === filter.actionmsgid)
  );

export const getKeywordsMessages = createSelector(
  (state: any) => state.entities.listmessages.keywords,
  (keywords) => keywords

);

export const getContactsList = createSelector(
  (state: any) => state.entities.listmessages.contacts,
  (contacts) => contacts

);
export const getContactMsgsList = createSelector(
  (state: any) => state.entities.listmessages.contactmsgs,
  (contactmsgs) => contactmsgs

);

export const getMsgType = createSelector(
  (state: any) => state.entities.listmessages.msgtype,
  (msgtype) => msgtype

);



export const getfilter = createSelector(
  (state: any) => state.entities.listmessages.filter,
  (filter) => filter

);
export const getSurvaycallcenter = createSelector(
  (state: any) => state.entities.listmessages.survaycallcenter,
  (survaycallcenter) => survaycallcenter

);
export const getSurvaycallcenterDetailsWMobile = createSelector(
  (state: any) => state.entities.listmessages.survaycallcenterDetailsWMobile,
  (survaycallcenterDetailsWMobile) => survaycallcenterDetailsWMobile

);
export const getSurvaycallcenterDetails = createSelector(
  (state: any) => state.entities.listmessages.survaycallcenterDetails,
  (survaycallcenterDetails) => survaycallcenterDetails

);
export const getSurvayL2 = createSelector(
  (state: any) => state.entities.listmessages.survayL2,
  (survayL2) => survayL2

);
export const getSurvayL2Details = createSelector(
  (state: any) => state.entities.listmessages.survayL2Details,
  (survayL2Details) => survayL2Details

);
export const getSurvayTotalTempMsgDetails = createSelector(
  (state: any) => state.entities.listmessages.survayTotalTempMsgDetails,
  (survayTotalTempMsgDetails) => survayTotalTempMsgDetails

);
export const getSurvayTotalTempMsg = createSelector(
  (state: any) => state.entities.listmessages.survayTotalTempMsg,
  (survayTotalTempMsg) => survayTotalTempMsg

);


export const getSurvayl1 = createSelector(
  (state: any) => state.entities.listmessages.survayl1,
  (survayl1) => survayl1

);

// export const getMediaMsgText = createSelector(
//   (state) => state.entities.msgtexts.MsgText,
//   (MsgText) => MsgText
// );

function findArrayElementByTitle(array: any, elem: any) {
  return array.find((element: any) => {
    return element.elem;
  });
}
