import React, { lazy } from 'react';
import { dashboardMenu, demoPages, layoutMenu, companyMsgList, FlowChart, WPChatMenu, WPSurvey, WPContactList, DocPages } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/Dashboard')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
	IMAGE: lazy(() => import('../pages/messages/company/Image')),
	TEXT: lazy(() => import('../pages/messages/company/Text')),
	FREETEXT: lazy(() => import('../pages/messages/company/FreeText')),
	VIDEO: lazy(() => import('../pages/messages/company/Video')),
	AUDIO: lazy(() => import('../pages/messages/company/Audio')),
	DOCUMENT: lazy(() => import('../pages/messages/company/Document')),
	STICKER: lazy(() => import('../pages/messages/company/Sticker')),
	CONTACT: lazy(() => import('../pages/messages/company/Contact')),
	LOCATION: lazy(() => import('../pages/messages/company/Location')),
	WPFLOWCHART: lazy(() => import('../pages/messages/company/WPFlowChart')),
	INTERACTIVELIST: lazy(() => import('../pages/messages/company/InteractiveList')),
	INTERACTIVEBUTTONS: lazy(() => import('../pages/messages/company/InteractiveButtons')),
	WPCHAT: lazy(() => import('../pages/chat/chat')),
	WPSURVEY: lazy(() => import('../pages/survey/survey')),
	APIDOC: lazy(() => import('../pages/documents/apidoc')),
	CHATBOTDOC: lazy(() => import('../pages/documents/apidoc')),
	WPCONTACTLIST: lazy(() => import('../pages/contactslist/contactslist')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: companyMsgList.messagesKind.subMenu.image.path,
		element: <PAGE_LAYOUTS.IMAGE />,
		exact: true,
	},
	{
		path: companyMsgList.messagesKind.subMenu.audio.path,
		element: <PAGE_LAYOUTS.AUDIO />,
		exact: true,
	},
	{
		path: companyMsgList.messagesKind.subMenu.contact.path,
		element: <PAGE_LAYOUTS.CONTACT />,
		exact: true,
	},
	{
		path: WPChatMenu.WPChat.path,
		element: <PAGE_LAYOUTS.WPCHAT />,
		exact: true,
	},
	{
		path: WPSurvey.WPSurvey.path,
		element: <PAGE_LAYOUTS.WPSURVEY />,
		exact: true,
	},
	{
		path: WPContactList.WPContactList.path,
		element: <PAGE_LAYOUTS.WPCONTACTLIST />,
		exact: true,
	},
	{
		path: DocPages.APIDoc.path,
		element: <PAGE_LAYOUTS.APIDOC />,
		exact: true,
	},
	{
		path: DocPages.ChatBot.path,
		element: <PAGE_LAYOUTS.CHATBOTDOC />,
		exact: true,
	},
	{
		path: WPChatMenu.WPChat.path,
		element: <PAGE_LAYOUTS.WPCHAT />,
		exact: true,
	},
	{
		path: companyMsgList.messagesKind.subMenu.location.path,
		element: <PAGE_LAYOUTS.LOCATION />,
		exact: true,
	},
	{
		path: FlowChart.FlowChart.path,
		element: <PAGE_LAYOUTS.WPFLOWCHART />,
		exact: true,
	},
	{
		path: companyMsgList.messagesKind.subMenu.document.path,
		element: <PAGE_LAYOUTS.DOCUMENT />,
		exact: true,
	},
	{
		path: companyMsgList.messagesKind.subMenu.freetext.path,
		element: <PAGE_LAYOUTS.FREETEXT />,
		exact: true,
	},
	{
		path: companyMsgList.messagesKind.subMenu.text.path,
		element: <PAGE_LAYOUTS.TEXT />,
		exact: true,
	},
	{
		path: companyMsgList.messagesKind.subMenu.video.path,
		element: <PAGE_LAYOUTS.VIDEO />,
		exact: true,
	},
	{
		path: companyMsgList.messagesKind.subMenu.InteractiveList.path,
		element: <PAGE_LAYOUTS.INTERACTIVELIST />,
		exact: true,
	},
	{
		path: companyMsgList.messagesKind.subMenu.InteractiveButtons.path,
		element: <PAGE_LAYOUTS.INTERACTIVEBUTTONS />,
		exact: true,
	},
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: layoutMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
		exact: true,
	},


];
const contents = [...presentation];

export default contents;
